import login_popup_bg from "../assets/images/userreward-info.png";
import reward_icon_claimed from "../assets/images/reward_icon_claimed.png";
import reward_icon_done from "../assets/images/reward_icon_done.png";
import reward_icon_locked from "../assets/images/reward_icon_locked.png";
import reward_red_arrow from "../assets/images/reward_red_arrow.svg";
import reward_btn_char from "../assets/images/reward_btn_char.png";

import styles from '../assets/styles/Reward.module.scss';

const stampType=[
    {
        name:"reward_icon_claimed",
        img:reward_icon_claimed
    },//claimed
    {
        name:"reward_icon_done",
        img:reward_icon_done
    },//done
    {
        name:"reward_icon_locked",
        img:reward_icon_locked
    }//lock
]

function RewardStampCon({type}){
    return (
        <div className={styles.reward_stamp_con}>
            <img src={stampType[type].img} className={styles.reward_stamp_icon} alt={stampType[type].name}/>  
        </div>
    );
}

export default function RewardOverviewModule({isExtra,stickerCount,usedStickerCount,onClick}) {

    const maxSticker=isExtra?8:7;
    function range(start, end) {
        let nums = [];
        for (let i = start; i < end; i++) nums.push(i);
        return nums;
    }

    let stickers = range(0, maxSticker);

  return (  
    <div className={styles.login_con_wrap}>
            <div className={styles.login_popup_con}>
                <img className={styles.login_popup_bg} src={login_popup_bg} alt="login_popup_bg"/>   
                <div className={styles.reward_info_wrap}>
                    <div className={styles.reward_info_top_con}>
                        <div className={styles.reward_stats_con}>
                            <p className={styles.reward_stats_title}>我的鬍鬚襟章</p>
                            <div className={styles.reward_stats_progress}>
                                <p className={styles.reward_stats_done}>
                                {(stickerCount-usedStickerCount).toString().split("").map((e,i)=>(<img src={require(`../assets/images/reward/stamp_num/`+e+".svg")}/>))}
                                </p>
                                <p className={styles.reward_stats_remain}>
                                    <i className={styles.reward_stats_divider}>/</i>{maxSticker}
                                </p>
                            </div>
                        </div>
                        <div className={styles.reward_stamp_wrap}>
                            { stickers.map((e,i)=>(
                                <RewardStampCon key={i} type={i<usedStickerCount?0:(i<stickerCount?1:2)}/>
                                
                            ))
                            }
                        </div>
                    </div>
                    <div onClick={stickerCount<maxSticker?onClick:null} className={styles.reward_info_bottom_con}>
                        <div className={styles.reward_info_btn_space}/>
                        <div className={styles.reward_info_btn_con}>
                            {stickerCount>=maxSticker && <div className={styles.reward_info_btn_text}>睇嚟人人都做咗Santa啦！</div>}
                            {stickerCount<maxSticker && <div className={styles.reward_info_btn_text}>睇嚟人人都做咗Santa啦！</div>}
                        </div>
                        {stickerCount<maxSticker && <div className={styles.reward_info_btn_icon}>
                            <img src={reward_red_arrow} style={{opacity:0}} className={styles.reward_info_btn_arrow} alt="reward_red_arrow"/>  
                        </div>}
                        <div className={styles.reward_info_btn_char}>
                            <img src={reward_btn_char} className={styles.reward_info_btn_img} alt="reward_btn_char"/>  
                        </div>
                    </div>
                </div>   
            </div>
        </div>
  );
}