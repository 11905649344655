import bg_landing_pc from "../assets/images/bg_landing_pc.png";
import logo from "../assets/images/logo.png";
import bg_desktop from "../assets/images/bg_desktop.png";
import santa_title from "../assets/images/santa_title.png";
import landing_qr_code from "../assets/images/landing_qr_code.png";
import landing_ppl_left from "../assets/images/landing_ppl_left.png";
import landing_ppl_right from "../assets/images/landing_ppl_right.png";
import discoball from "../assets/images/discoball_pc.png";
import light from "../assets/images/light_pc.png";
import floor from "../assets/images/floor_pc.png";


import styles from "../assets/styles/LandingDesktop.module.scss"


function LandingDesktop() {
    return (
    <div className={styles.main_center_pc}>
        <img src={bg_landing_pc} className={styles.landing_pc_bg}/>
        <div className={styles.light_pc}>
            <img src={light}/>
        </div>
        <div className={styles.floor_pc}>
            <img src={floor}/>
        </div>
        <div className={styles.floor_color}></div>
        <div className={styles.discoball_pc}>
            <img src={discoball}/>
        </div>
        <div className={styles.landing_pc_wrap}>
            <div className={styles.landing_pc_logo_con}>
                <img className={styles.landing_pc_logo_img} src={logo}/>
            </div>
            <div className={styles.landing_pc_con}>
                <img src={bg_desktop} className={styles.landing_pc_inner_bg}/>
                <img src={santa_title} className={styles.landing_pc_inner_title}/>
                <div className={styles.landing_pc_inner_con}>
                    <div className={styles.landing_pc_text}>即刻用手機 Scan 呢個 QR code<br/>參與萬寧 Santa 大招募<br/>人人都可以做 Santa !</div>
                    <img src={landing_qr_code} className={styles.landing_pc_qr_code}/>
                </div>
            </div>
            <img src={landing_ppl_left} className={styles.landing_pc_ppl_l}/>
            <img src={landing_ppl_right} className={styles.landing_pc_ppl_r}/>
        </div>
    </div>
    );
}

export default LandingDesktop;