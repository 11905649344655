import Cookies from 'js-cookie';
const HOST = process.env.REACT_APP_API_URL;

export function login(next="") {
    //console.log("test");
    if(next=="slider")
        window.location.href = HOST + '/api/facebook?next=slider';
    else if(next=="reward")
        window.location.href = HOST + '/api/facebook?next=reward';
    else
        window.location.href = HOST + '/api/facebook';
}

export function checkAuth(){
    return fetch(HOST + '/api/user/info', {
        method: 'POST',
        credentials: 'include',
        // headers: {
        //     'Authorization': 'Bearer ' + token
        // }
    });
}

export function getStamp() {
    // const token = Cookies.get('token', { domain: '.manningscampaign.com.hk' }) || Cookies.get('token');
    return fetch(HOST + '/api/printing/get', {
        method: 'POST',
        credentials: 'include',
        // headers: {
        //     'Authorization': 'Bearer ' + token
        // }
    });

}

export function createStamp(task) {
    // const token = Cookies.get('token', { domain: '.manningscampaign.com.hk' }) || Cookies.get('token');
    return fetch(HOST + '/api/printing/create', {
        method: 'POST',
        credentials: 'include',
        headers: {
            // 'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            type: task
        })
    });

}

export function createFdStamps() {
    // const token = Cookies.get('token', { domain: '.manningscampaign.com.hk' }) || Cookies.get('token');

    return fetch(HOST + '/api/printing/fd/create', {
        method: 'POST',
        credentials: 'include',
        headers: {
            // 'Authorization': 'Bearer ' + token,
        }
    });

}

export function getInviteCodes() {
    // const token = Cookies.get('token', { domain: '.manningscampaign.com.hk' }) || Cookies.get('token');

    return fetch(HOST + '/api/user/inviteCode/get', {
        method: 'POST',
        credentials: 'include',
        headers: {
            // 'Authorization': 'Bearer ' + token
        }
    });
}

export function createInviteCode(friendName) {
    // const token = Cookies.get('token', { domain: '.manningscampaign.com.hk' }) || Cookies.get('token');

    return fetch(HOST + '/api/user/inviteCode/create', {
        method: 'POST',
        credentials: 'include',
        headers: {
            // 'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            FriendName: friendName
        })
    });
}

export function listCoupon() {
    // const token = Cookies.get('token', { domain: '.manningscampaign.com.hk' }) || Cookies.get('token');

    return fetch(HOST + '/api/coupon/list', {
        method: 'POST'
    });
}

export function getUserCoupon() {
    // const token = Cookies.get('token', { domain: '.manningscampaign.com.hk' }) || Cookies.get('token');

    return fetch(HOST + '/api/coupon/get', {
        method: 'POST',
        credentials: 'include',
        headers: {
            // 'Authorization': 'Bearer ' + token
        }
    });
}

export function stampToCoupon(id){
    // const token = Cookies.get('token', { domain: '.manningscampaign.com.hk' }) || Cookies.get('token');

    return fetch(HOST + '/api/printing/PrintingToCoupon', {
        method: 'POST',
        credentials: 'include',
        headers: {
            // 'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            coupon: id
        })
    });
}

export function consumeCoupon(id){
    // const token = Cookies.get('token', { domain: '.manningscampaign.com.hk' }) || Cookies.get('token');

    return fetch(HOST + '/api/coupon/use', {
        method: 'POST',
        credentials: 'include',
        headers: {
            // 'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            userCoupon: id
        })
    });
}
