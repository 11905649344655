import Header from '../components/HeaderModule';
import rewardTitleImg from "../assets/images/reward_header_title.png";
import bg_reward from "../assets/images/bg_reward.png";
import reward_light_top from "../assets/images/reward_light_top.png";
import reward_light_bottom from "../assets/images/reward_light_bottom.png";
import RewardOverviewModule from '../components/RewardOverviewModule';
import RewardCollectionModule from '../components/RewardCollectionModule';
import LoginModule from '../components/Login';
import CouponModule from '../components/CouponModule';
import isAuthenticated from "../middlewares/isAuthenticated";
import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from '../assets/styles/Reward.module.scss';

import {createFdStamps,getInviteCodes,login,getStamp,listCoupon,getUserCoupon, stampToCoupon,consumeCoupon} from "../services/apiCall";

import logoPizzaHut from '../assets/images/logo_pizza_hut.png';
import couponPizzaHut from '../assets/images/coupon_pizza_hut.png';
import couponPizzaHutClaimed from '../assets/images/coupon_pizza_hut_claimed.png';
import couponPopupPizzaHut from '../assets/images/coupon_popup_pizza_hut.svg';
import couponQRPizzaHut from '../assets/images/pizza_hut_qr.png';
import titlePH from '../assets/images/title_ph.svg';

import logoPHD from '../assets/images/logo_phd.png';
import couponPHD from '../assets/images/coupon_phd.png';
import couponPHDClaimed from '../assets/images/coupon_phd_claimed.png';
import couponPopupPHD from '../assets/images/coupon_popup_phd.svg';
import couponQRPHD from '../assets/images/phd_qr.png';
import titlePHD from '../assets/images/title_phd.svg';

import ReactGA from 'react-ga4';





import { set } from 'mongoose';


function Reward() {
    let navigate = useNavigate();
    const [firstShow, setFirstShow] = useState(true);
    const [authPromise,setAuthPromise]=useState(isAuthenticated());
    const [auth,setAuth]=useState();
    const [stickerCount,setStickerCount]=useState(0);
    const [usedStickerCount,setUsedStickerCount]=useState(0);
    const [couponList,setCouponList]=useState([]);
    const [userCouponList,setUserCouponList]=useState([]);
    const [showCouponPopup,setShowCouponPopup] =useState(false);
    const [curCouponId,setCurCouponId]=useState(null);
    const [curUserCouponId,setCurUserCouponId]=useState(null);
    const [curQuantity,setCurQuantity]=useState(0);
    const [isRedeem, setIsRedeem]=useState(true);
    const [showRight,setShowRight]=useState(true);
    const [showQrCode, setShowQrCode]=useState(false);
    const [couponNotReady, setCouponNotReady] = useState(false);
    const [isExtra,setIsExtra] =useState(false);
    const [showFdHints,setShowFdHints]=useState(false);
    const [inviteCodeList, setInviteCodeList] = useState([{},{},{}]);
    const [addFdStampNum,setAddFdStampNum]=useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    if(searchParams.get("newUser")){
      ReactGA.event({
        category: "newUserLogin",
        action: "newUserLogin",
        label: searchParams.get("newUser"),
      })
    }
    if(searchParams.get("invited")){
      ReactGA.event({
        category: "invited",
        action: "createStamp",
        label: "invited",
        value:1
      })
    }
    const couponType=[
      {
        name:"Pizza Hut",
        logo:logoPizzaHut,
        couponImg:couponPizzaHut,
        couponClaimedImg:couponPizzaHutClaimed,
        couponPopupImg:couponPopupPizzaHut,
        couponQrImg:couponQRPizzaHut,
        title:titlePH
      },
      {
        name:"PHD",
        logo:logoPHD,
        couponImg:couponPHD,
        couponClaimedImg:couponPHDClaimed,
        couponPopupImg:couponPopupPHD,
        couponQrImg:couponQRPHD,
        title:titlePHD
      }
    ]

    function addQ(){
      if((curQuantity+1)*couponList[curCouponId].printingRequest<=(stickerCount-usedStickerCount)){
        setCurQuantity(curQuantity+1);
      }
    }

    function minusQ(){
      if(curQuantity>1){
        setCurQuantity(curQuantity-1);
      }
    }

    function _getStamp(){
      var res=getStamp()
      .then((res)=>res.json())
      .then((data)=>{
        //console.log(data);
        setStickerCount(data.Printing.length);
        var usedSticker=0;
        data.Printing.forEach((e,i)=>{
          if(e.state=="Used"){
            usedSticker++;
          }
          if(e.type=="invited"){
            setIsExtra(true);
          }
        });
        setUsedStickerCount(usedSticker);
      });
    }

    function _listCoupon(){
      var res=listCoupon()
      .then((res)=>res.json())
      .then((data)=>{
        console.log(data);
        var temp=[];
        data.Coupon.forEach((e,i)=>{
          const type=couponType.find((ele)=>e.name.includes(ele.name));
          e.logo=type.logo;
          e.couponImg=type.couponImg;
          e.couponClaimedImg=type.couponClaimedImg;
          e.couponPopupImg=type.couponPopupImg;
          e.title=type.title
          temp.push(e);
        });
        setCouponList(temp);
      });
    }

    function _getUserCoupon(){
      var res=getUserCoupon()
      .then((res)=>res.json())
      .then((data)=>{
        console.log(data);
        var temp=[];
        data.UserCoupon.forEach((e,i)=>{
          const type=couponType.find((ele)=>e.couponID.name.includes(ele.name));
          e.couponID.logo=type.logo;
          e.couponID.couponImg=type.couponImg;
          e.couponID.couponClaimedImg=type.couponClaimedImg;
          e.couponID.couponPopupImg=type.couponPopupImg;
          e.couponID.couponQrImg=type.couponQrImg;
          e.couponID.title=type.title;
          temp.push(e);
        });
        setUserCouponList(temp);
        /*if(firstShow && temp.length==0){
          setShowRight(false);
        }*/
      });
    }

    function redeemCoupon(i){
      setShowQrCode(false);
      setCurCouponId(i);
      setCurQuantity(1);
      setCouponNotReady(false);
      setShowCouponPopup(true);
      setIsRedeem(true);
      //console.log(curCouponId);
      //console.log(couponList.length);
    }

    async function _stampToCoupon(){
      if((stickerCount-usedStickerCount)>=curQuantity*couponList[curCouponId].printingRequest){
        for(var i=0;i<curQuantity;i++){
          var res=await stampToCoupon(couponList[curCouponId].coupon)
          var data=await res.json();
          if(!data.result){
            console.log(data)
            return;
          }
          ReactGA.event({
            category: couponList[curCouponId].name,
            action: "createCoupon",
            label: couponList[curCouponId].name,
          })
          ReactGA.event({
            category: couponList[curCouponId].name,
            action: "consumeStamp",
            label: couponList[curCouponId].name,
            value: couponList[curCouponId].printingRequest
          })
        }

        _getUserCoupon();
        setShowCouponPopup(false);
        setShowRight(false);
        _getStamp();
      }
    }

    function _useCoupon(i){
      setShowQrCode(false);
      setCurUserCouponId(i);
      setShowCouponPopup(true);
      setCouponNotReady(false);
      setIsRedeem(false);
      //console.log(curUserCouponId);
      //console.log(couponList.length);
    }

    function _consumeCoupon(){
      var res=consumeCoupon(userCouponList[curUserCouponId]._id)
      .then((res)=>res.json())
      .then((data)=>{
        console.log(data);
        if(data.result){
          setIsRedeem(true);
          setCouponNotReady(true);
          _getUserCoupon();
          setShowQrCode(true);
          
          ReactGA.event({
            category: userCouponList[curUserCouponId].couponID.name,
            action: "consumeCoupon",
            label: userCouponList[curUserCouponId].couponID.name,
          })
        }
        
      })
    }

    async function _createFdStamps(){
      return new Promise(async (resolve,reject)=>{
        var res=await createFdStamps();
        var data=await res.json();
        console.log(data);
        if(data.result){
          for(var i=0;i<data.count;i++){
            ReactGA.event({
              category: "fd",
              action: "createStamp",
              label: "fd",
              value:1
            })
          }
          setShowFdHints(true);
        }
        resolve();  
      });
    }
  
    function checkCanCreateStamp(){
      var res=getStamp()
          .then((res)=>res.json())
          .then(async (data)=>{
            //console.log(data);
            var _accepted=0;
            inviteCodeList.forEach((e,i)=>{
              if(e.InviteUserID){
                _accepted++;
              }
            });
            
            var _fdStamp=0;
            data.Printing.forEach((e,i)=>{     
              if(e.type.substring(0,3)=="fd_"){
                _fdStamp++
              }
            });
            console.log("accepted: "+_accepted);
            console.log("fdStamp: "+_fdStamp);
            setAddFdStampNum(_accepted-_fdStamp);
            if(_accepted>_fdStamp){
              await _createFdStamps();
              _getStamp();
              return true;
            }
            return false;
          });
    }
  
    function updateInviteCodeList(){
      var res=getInviteCodes()
      .then((res)=>res.json())
      .then((data)=>{
        var temp=inviteCodeList.concat();
        //console.log(data);
        console.log(temp);
        data.UserInviteCodes.forEach((e,i)=>{
          temp[i]=e;
          if(i>=temp.length)
            return;
  
        });
        console.log(temp);
        setInviteCodeList(temp);
      });
      //console.log(res);
    }

    useEffect(() => {
      if(firstShow){
        window.scrollTo(0,0);
        document.querySelector("body").classList.add("deep_color_bg");
        document.querySelector("body").style.overflow="hidden";
        _listCoupon();
        //console.log(auth);
        //console.log(authPromise);
        authPromise.then(ret=>{
          console.log(ret);
          setAuth(ret);
        })
        // if(auth){
        //   _getStamp();
        //   _getUserCoupon();
        //   updateInviteCodeList();
        //   //console.log(res);
        // }
        setFirstShow(false);
      }
      
    });

    useEffect(()=>{
      _getStamp();
      _getUserCoupon();
      updateInviteCodeList();
    },[auth])

    useEffect(()=>{
      var count=0;
      inviteCodeList.forEach((e,i)=>{
        if(e.InviteCode){
          count++;
        }
      });
      checkCanCreateStamp();
    },[inviteCodeList]);

  return (
    <div>
      <div>
      <div className={styles.reward_center}>
        <Header backBtnOnClick={()=>{navigate('/')}} hasTitle={true} titleImage={rewardTitleImg}/>
        <div className={styles.bgDiv}>
          <img src={bg_reward} className={styles.reward_bg} alt="reward_bg"/>  
        </div>
        <img src={reward_light_top} className={styles.reward_light_top}/>  
        <img src={reward_light_bottom} className={styles.reward_light_bottom}/> 
        {auth && (<RewardOverviewModule isExtra={isExtra} onClick={()=>{}} stickerCount={stickerCount} usedStickerCount={usedStickerCount}/>)}{!auth && (<LoginModule loginBtnOnClick={()=>{login("reward");}} isPopup={false}/>)}
        <RewardCollectionModule 
          redeemCoupon={redeemCoupon}
          _useCoupon={_useCoupon}
          couponList={couponList} 
          userCouponList={userCouponList} 
          noRedeem={!auth}
          showRight={showRight}
          setShowRight={setShowRight}
        />
        {/* <RewardCollectionModule noRedeem={false}/> */}
        
    </div>
      </div>
      {showCouponPopup && (
        <CouponModule
          isRedeem={isRedeem}
          remainSticker={stickerCount-usedStickerCount}
          curQuantity={curQuantity}
          coupon={showRight?(curCouponId<couponList.length?couponList[curCouponId]:{}):(curUserCouponId<userCouponList.length?userCouponList[curUserCouponId].couponID:{})}
          dismiss={()=>{setShowCouponPopup(false)}}
          onRedeemClick={_stampToCoupon}
          cancelRedeemOnClick={()=>setShowCouponPopup(false)}
          confirmRedeemOnClick={_consumeCoupon}
          showQrCode={showQrCode}
          couponNotReady={couponNotReady}
          addQ={addQ}
          minusQ={minusQ}
        />
      )}
      {showFdHints && <div onClick={()=>setShowFdHints(false)} className={styles.number_of_stamp_style}>你嘅朋友已經登入，你嘅鬍鬚襟章數＋{addFdStampNum}</div>}
    </div>
  );
}

export default Reward;
