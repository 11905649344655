import dismissBtn from '../assets/images/dismiss_btn.png';
import loginPopupBg from "../assets/images/login_popup_bg.png";
import userreward_login_popup_bg from "../assets/images/userreward-info.png";
import inviteTitle from "../assets/images/inviteTitle.png";
import inviteErrorTitle from "../assets/images/inviteErrorTitle.png";
import titleImg from "../assets/images/logn_title.png";
import FontReload from '../components/FontReload';

import styles from "../assets/styles/Login.module.scss";

export default function LoginModule({isPopup,isInvited,isInvitedError,dismissBtnOnClick, loginBtnOnClick}) {
  switch(isPopup){
    case true: 
      return <LoginPopup isInvited={isInvited} isInvitedError={isInvitedError} dismissBtnOnClick={dismissBtnOnClick} loginBtnOnClick={loginBtnOnClick} />;
    case false:
      return <LoginCon loginBtnOnClick={loginBtnOnClick}/>;
  }
}

const LoginPopup=({isInvited=false, isInvitedError=false, dismissBtnOnClick, loginBtnOnClick})=> {
  return (  
    <div>
      <div>
        <div className={styles.slider_center}>
          <div className={styles.popup_bg}>
            <div className={styles.login_popup_wrap}>
              <img onClick={dismissBtnOnClick} className={styles.dismiss_btn_con} src={dismissBtn} alt="dismissBtn"></img> 
              <div className={styles.login_popup_con}>
                <img className={styles.login_popup_bg} src={loginPopupBg} alt="loginPopupBg"></img>
                <div className={styles.login_popup_text_con}>
                  {isInvited && <img style={{width:"31.866vw"}} className={styles.login_title} src={inviteTitle}/>}
                  {isInvitedError && <img style={{width:"59.467vw"}} className={styles.login_title} src={inviteErrorTitle}/>}
                  {!isInvited && !isInvitedError && <img className={styles.login_title} src={titleImg}/>}
                  {/* <p className={styles.login_popup_title}>喂喂～ 登入先啦！</p> */}
                  {isInvited && <p className={styles.login_popup_content}>SANTA大募集! 邀請你一齊遊戲贏獎品!<br/>立即登入啦!</p>}
                  {isInvitedError && <p className={styles.login_popup_content}>已經有人用咗呢個邀請連結, 想盡快贏獎品<br/>就按下面個掣登入玩遊戲啦!</p>}
                  {!isInvited && !isInvitedError && <p className={styles.login_popup_content}>立即登入及參與<br/>人人Santa大募集，有得獲贈鬍鬚襟章，<br/>換領萬寧精選優惠！</p>}
                  <button className={styles.login_popup_btn} onClick={loginBtnOnClick}>以 Facebook 登入</button>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>
      <FontReload />
    </div>
  );
}

const LoginCon=({loginBtnOnClick})=> {
  return (  
    <div class={styles.login_con_wrap}>
      <div class={styles.login_popup_con}>
          <img style={{width:"100%"}} class={styles.login_popup_bg} src={userreward_login_popup_bg}/>   
          <div class={styles.login_popup_text_con}>
              <img className={styles.login_title} src={titleImg}/>
              {/* <p class={styles.login_popup_title}>喂喂～ 登入先啦！</p> */}
              <p class={styles.login_popup_content}>立即登入及參與<br/>人人Santa大募集，有得獲贈鬍鬚襟章，<br/>換領萬寧精選優惠！</p>
              <button class={styles.login_popup_btn} onClick={loginBtnOnClick}>以 Facebook 登入</button>
          </div> 
      </div>
    </div> 
  );
}