
import coupon_pizza_hut from "../assets/images/coupon_pizza_hut.png";
import coupon_icon_mustache from "../assets/images/coupon_icon_mustache.svg";
import coupon_phd from "../assets/images/coupon_phd.png";
import reward_coupon_bg_left from "../assets/images/reward_coupon_bg_left.svg";
import reward_coupon_left_title_img from "../assets/images/reward_coupon_left_title.png";
import PizzaHutCouponModule from "./PizzaHutCouponModule";
import PHDCouponModule from "./PHDCouponModule";
import GeneralCouponModule from "./GeneralCouponModule";

import styles from '../assets/styles/Reward.module.scss';

export default function RewardsLeftModule({showRedeemded,onClick,list,redeemCoupon}) {
  var redeemStatus = showRedeemded;
  //console.log(redeemStatus);


    function onChangeStatus(){
      redeemStatus=true;
      //console.log(redeemStatus);
  }
  return (  
    <div className={`${styles.reward_coupon_wrap} ${redeemStatus?styles.hide_under:styles.coupon_reward}`}>
      
        {/* <!-- Change the z-index in coupon_reward & coupon_claim to switch the layer position -->  */}
        <div className={styles.reward_coupon_con}>
            <img src={reward_coupon_bg_left} className={styles.reward_coupon_bg}/>  
            <div className={styles.reward_coupon_title_con}>
              {/* <div className={styles.reward_coupon_title_left}  onClick={()=>onClick(true)}>獎賞</div> */}
              <div className={styles.reward_coupon_title_left}  onClick={()=>onClick(true)}><img src={reward_coupon_left_title_img} className={styles.titleLeftImg}/></div>
              <div className={styles.reward_coupon_title_right}  onClick={()=>onClick(false)}/>
            </div>
            <div className={styles.reward_coupon_inner_bg}>
              <div className={styles.reward_coupon_inner_con}>
                {list.map((e,i)=>(
                  <GeneralCouponModule key={i} onClick={()=>redeemCoupon(i)} couponImg={e.couponImg} couponClaimedImg={e.couponClaimedImg} redeemCase={"canRedeem"} redeemNum={e.printingRequest} />
                ))}
              </div>
            </div>
        </div> 
    </div>
  );
}