import coupon_icon_mustache from "../assets/images/coupon_icon_mustache.svg";

import styles from '../assets/styles/Reward.module.scss';

export default function GeneralCouponModule({couponImg,couponClaimedImg,redeemCase, redeemNum, onClick}) {
  switch(redeemCase){
    case "canRedeem": 
    return <GeneralCouponCanRedeem couponImg={couponImg} redeemNum={redeemNum} onClick={onClick}/>;
    case "canUse": 
    return <GeneralCouponCanUse couponImg={couponImg} onClick={onClick}/>;
    case "used": 
    return <GeneralCouponUsed couponClaimedImg={couponClaimedImg}/>;
}
}

const GeneralCouponCanRedeem=({onClick, redeemNum,couponImg})=> {
  return (  
    <div className={styles.coupon_inner_con}>
        <img src={couponImg} className={styles.coupon_img}/>  
        <button onClick={onClick} className={styles.coupon_inner_btn}>
        <div className={styles.coupon_inner_btn_wrap}>
            <div>兌換</div>
            <img src={coupon_icon_mustache} className={styles.coupon_claim_icon}/>
            <div>{redeemNum}</div>
        </div>       
        </button>
    </div>
  );
}

const GeneralCouponCanUse=({onClick,couponImg})=> {
  return (  
    <div className={styles.coupon_inner_con}>
        <img src={couponImg} className={styles.coupon_img}/>  
        <button onClick={onClick} className={styles.coupon_inner_btn}>
            <div className={styles.coupon_inner_btn_wrap}>
                <div>使用優惠</div>
            </div>     
        </button>
    </div>
  );
}

const GeneralCouponUsed=({couponClaimedImg})=> {
  return (  
<div className={styles.coupon_inner_con}>
    <img src={couponClaimedImg} className={styles.coupon_img}/>  
</div>
  );
}