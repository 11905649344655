
import coupon_phd from "../assets/images/coupon_phd.png";
import coupon_phd_claimed from "../assets/images/coupon_phd_claimed.png";
import coupon_icon_mustache from "../assets/images/coupon_icon_mustache.svg";

import styles from '../assets/styles/Reward.module.scss';

export default function PHDCouponModule({onClick, redeemCase, redeemNum}) {
  switch(redeemCase){
    case "canRedeem": 
    return <PHDCouponCanRedeem redeemNum={redeemNum} onClick={onClick}/>;
    case "canUse": 
    return <PHDCouponCanUse onClick={onClick}/>;
    case "used": 
    return <PHDCouponUsed/>;
}
}

const PHDCouponCanRedeem=({onClick, redeemNum})=> {
  return (  
    <div className={styles.coupon_inner_con}>
    <img src={coupon_phd} className={styles.coupon_img}/>  
    <button className={styles.coupon_inner_btn}>
        <div className={styles.coupon_inner_btn_wrap}>
            <div>兌換</div>
            <img src={coupon_icon_mustache} className={styles.coupon_claim_icon}/>
            <div>{redeemNum}</div>
        </div>     
        </button>
    </div>
  );
}

const PHDCouponCanUse=({onClick})=> {
  return (  
    <div className={styles.coupon_inner_con}>
      <img src={coupon_phd} className={styles.coupon_img}/>  
      <button className={styles.coupon_inner_btn}>
          <div className={styles.coupon_inner_btn_wrap}>
              <div>使用優惠</div>
          </div>     
      </button>
    </div>
  );
}

const PHDCouponUsed=({})=> {
  return (  
<div className={styles.coupon_inner_con}>
                        <img src={coupon_phd_claimed} className={styles.coupon_img}/>  
                    </div>
  );
}