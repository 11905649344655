import { useEffect } from "react";

export default function FontReload() {
    useEffect(() =>{
        if(typeof window !== "undefined" && typeof window._jf !== 'undefined'){
            try{
                window._jf.flush();
                console.log('reloaded font');
            }catch(e){

            }
        }
    });
    return null;
}