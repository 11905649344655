
import coupon_pizza_hut from "../assets/images/coupon_pizza_hut.png";
import coupon_icon_mustache from "../assets/images/coupon_icon_mustache.svg";
import coupon_pizza_hut_claimed from "../assets/images/coupon_pizza_hut_claimed.png";

import styles from '../assets/styles/Reward.module.scss';

export default function PizzaHutCouponModule({redeemCase, redeemNum, onClick}) {
  switch(redeemCase){
    case "canRedeem": 
    return <PizzaHutCouponCanRedeem redeemNum={redeemNum} onClick={onClick}/>;
    case "canUse": 
    return <PizzaHutCouponCanUse onClick={onClick}/>;
    case "used": 
    return <PizzaHutCouponUsed/>;
}
}

const PizzaHutCouponCanRedeem=({onClick, redeemNum})=> {
  return (  
    <div className={styles.coupon_inner_con}>
        <img src={coupon_pizza_hut} className={styles.coupon_img}/>  
        <button onClick={onClick} className={styles.coupon_inner_btn}>
        <div className={styles.coupon_inner_btn_wrap}>
            <div>兌換</div>
            <img src={coupon_icon_mustache} className={styles.coupon_claim_icon}/>
            <div>{redeemNum}</div>
        </div>       
        </button>
    </div>
  );
}

const PizzaHutCouponCanUse=({onClick})=> {
  return (  
    <div className={styles.coupon_inner_con}>
                        <img src={coupon_pizza_hut} className={styles.coupon_img}/>  
                        <button className={styles.coupon_inner_btn}>
                            <div className={styles.coupon_inner_btn_wrap}>
                                <div>使用優惠</div>
                            </div>     
                        </button>
    </div>
  );
}

const PizzaHutCouponUsed=({})=> {
  return (  
<div className={styles.coupon_inner_con}>
                        <img src={coupon_pizza_hut_claimed} className={styles.coupon_img}/>  
                    </div>
  );
}