const productsList = [
  {
    id: 0,
    img: require( "../assets/images/claw/products/dad.png" ),
    name: "萬寧男士多種維他命礦物質100片",
  },

  {
    id: 1,
    img: require( "../assets/images/claw/products/mum.png" ),
    name: "LIESE泡泡染髮劑晚霞薰衣草",
  },

  {
    id: 2,
    img: require( "../assets/images/claw/products/sis.png" ),
    name: "AHC玻尿酸補水透亮精華液",
  },

  {
    id: 3,
    img: require( "../assets/images/claw/products/pa.png" ),
    name: "草姬靈芝孢子60粒",
  },

  {
    id: 4,
    img: require( "../assets/images/claw/products/hut.png" ),
    name: "",
  },
  {
    id: 5,
    img: require( "../assets/images/claw/products/phd.png" ),
    name: "",
  },
  {
    id: 6,
    img: require( "../assets/images/claw/products/pizza1.png" ),
    name: "<span style=\"white-space:nowrap\">Pizza Hut 期間限定極芝熔岩批</span><br/>極芝海鮮",
    pImg: require("../assets/images/claw/products/pizza1-p.png")
  },
  {
    id: 7,
    img: require( "../assets/images/claw/products/pizza2.png" ),
    name: "<span style=\"white-space:nowrap\">Pizza Hut 期間限定極芝熔岩批</span><br/>極芝狂牛",
    pImg: require("../assets/images/claw/products/pizza2-p.png")
  },
  {
    id: 8,
    img: require( "../assets/images/claw/products/pizza3.png" ),
    name: "PHD 港式Classic<br/>沙嗲牛肉批",
    pImg: require("../assets/images/claw/products/pizza3-p.png")
  },
  {
    id: 9,
    img: require( "../assets/images/claw/products/yuu.png" ),
    name: "",
  },

];

const targetPeople = [
  {
    name: "爹哋",
    avatarImg: require( "../assets/images/claw/avatars/dad-a.png" ),
    avatarTitleImg:require("../assets/images/claw/avatars/dad-b.png"),
    titleImg:require("../assets/images/claw/avatars/dad-title.png"),
    product: {
      id: 0,
      img: require( "../assets/images/claw/products/dad-p.png" ),
      name: "萬寧男士多種維他命礦物質\n100片",
    },
  },

  {
    name: "媽咪",
    avatarImg: require( "../assets/images/claw/avatars/mum-a.png" ),
    avatarTitleImg:require("../assets/images/claw/avatars/mum-b.png"),
    titleImg:require("../assets/images/claw/avatars/mum-title.png"),
    product: {
      id: 2,
      img: require( "../assets/images/claw/products/sis-p.png" ),
      name: "AHC玻尿酸補水透亮精華液",
    },
  },

  {
    name: "家姐",
    avatarImg: require( "../assets/images/claw/avatars/sis-a.png" ),
    avatarTitleImg:require("../assets/images/claw/avatars/sis-b.png"),
    titleImg:require("../assets/images/claw/avatars/sis-title.png"),
    product: {
      id: 1,
      img: require( "../assets/images/claw/products/mum-p.png" ),
      name: "LIESE泡泡染髮劑\n晚霞薰衣草",
    },
    
  },

  {
    name: "爺爺",
    avatarImg: require( "../assets/images/claw/avatars/pa-a.png" ),
    avatarTitleImg:require("../assets/images/claw/avatars/pa-b.png"),
    titleImg:require("../assets/images/claw/avatars/pa-title.png"),
    product: {
      id: 3,
      img: require( "../assets/images/claw/products/pa-p.png" ),
      name: "草姬靈芝孢子\n60粒",
    },
  },

];

export default { productsList, targetPeople };
