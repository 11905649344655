import sticker_header_title from "../assets/images/sticker_header_title.png";
import bgSticker from "../assets/images/bg_sticker.png";
import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import stickerDeco from "../assets/images/sticker_deco.png";
import Header from '../components/HeaderModule';
import CaneModule from '../components/CaneModule';
import GiftModule from '../components/Gift';

import { useNavigate } from "react-router-dom";

import {createStamp} from "../services/apiCall";

import '../assets/styles/Sticker.css';
import ReactGA from 'react-ga4';
const task="game2"

function Sticker() {
  //ReactGA.pageview(window.location.pathname)
  let navigate = useNavigate();
  const [firstShow, setFirstShow] = useState(true);
  const [showGift,setShowGift]=useState(false);

  function stickerRedirect(){
    window.open('https://wa.me/85260378852?text=%E6%88%91%E8%A6%81%E8%90%AC%E5%AF%A7%E8%81%96%E8%AA%95Sticker','_blank');
    gameClear();
  }

  useEffect(() => {
    if(firstShow){
      window.scrollTo(0,0);
      document.querySelector("body").style.overflow="hidden";
      document.querySelector("body").classList.add("green_color_bg");
      setFirstShow(false);
    }
    
  });

  useEffect(()=>{
    if(showGift){
        document.querySelector("body").style.overflow="hidden";
    }
    else{
        document.querySelector("body").style.overflow="auto";
    }
  },[showGift]);

  function gameClear(){
    createStamp(task)
    .then((res)=>res.json())
    .then((data)=>{
      console.log(data);
      if(data.result){
        ReactGA.event({
          category: task,
          action: task,
        })
        ReactGA.event({
          category: task,
          action: "createStamp",
          label: task,
          value:1
        })
        setShowGift(true);
      }
      else{
        navigate('/tutorial/3')
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      navigate('/tutorial/3');
    });
    
  }

  return (
    <div>
      <div>
      <div className="sticker_center">
        <Header 
          hasTitle={true} 
          titleImage={sticker_header_title} 
          backBtnOnClick={() => navigate('/tutorial/2')}/>
        {/* <!-- Bg Con --> */}
        <div className="bgDiv">
          <img src={bgSticker} className="sticker_bg" alt='bgSticker'></img>  
        </div>
        <img src={stickerDeco} className="sticker_bg_deco" alt='stickerDeco'></img>
        <CaneModule onDIYClick={() => navigate('/sticker-editor')} onDLClick={stickerRedirect}/>
    </div>
      </div>
      {showGift && (<GiftModule nextBtnOnClick={()=>navigate('/tutorial/3')}/>)}
    </div>
  );
}

export default Sticker;
