import pizza_hut_logo from "../assets/images/logo_pizza_hut.png";
import dismiss_btn_black from "../assets/images/dismiss_btn_black.png";

import styles from '../assets/styles/Coupon.module.scss';

export default function CouponUpperModule({coupon,showQR, qr_code_image, dismiss}) {
    console.log(coupon);
    return (  
        <div>        
            <img src={coupon.couponPopupImg} className={styles.coupon_popup_bg_img} alt="coupon_popup_pizza_hut"/>  
            <div className={styles.coupon_popup_header}>
                <div className={styles.coupon_header_short_width}></div>
                <div className={styles.coupon_header_long_width}>
                    <img src={coupon.logo} className={styles.coupon_popup_logo} alt={coupon.shop}/>  
                </div>
                <div className={styles.coupon_header_short_width}>
                    <img onClick={dismiss} src={dismiss_btn_black} className={styles.coupon_popup_dismiss} alt="dismiss_btn_black"/> 
                </div> 
            </div>
            <div className={styles.coupon_popup_con}>
                <div className={styles.coupon_popup_title}>
                    <img src={coupon.title}/>
                    {/* <div className={styles.coupon_popup_title_l}>{coupon.titleL}</div>
                    <div className={styles.coupon_popup_title_s}>{coupon.titleS}</div> */}
                </div>
                {/* <!-- Show coupon text description --> */}
                {(!showQR)?<div className={styles.coupon_popup_content} dangerouslySetInnerHTML={{__html:coupon.desc}}/>:null}
                {/* <!-- Show coupon QR code  */}
                {(showQR)?<div className={`${styles.coupon_popup_content} ${styles.coupon_popup_qr_code}`}>
                    <img src={coupon.couponQrImg} className={styles.coupon_popup_qr_img} alt="qr_code_dummy"/> 
                </div>:null}
            </div>
        </div>
    );
  }



