import * as Matter from "matter-js";
import React, { useEffect, useState, useMemo, useRef } from "react";
import PolyDecomp from "poly-decomp";

import Header from '../components/RelativeHeader';
import headerImg from '../assets/images/claw/title_header.png';

import GameInfo from "../constants/clawMachineGameInfo.js";
import '../assets/styles/ClawMachineCanvas.css';
import { grabberStickImg, grabberLeftSvg, grabberRightSvg, grabberLeftImg, grabberRightImg } from "../assets/images/claw";
import { arrowLeft, arrowRight, goLabel } from "../assets/images/claw";
import { useNavigate } from "react-router-dom";
import isAuthenticated from "../middlewares/isAuthenticated";
import GiftModule from '../components/Gift';

import {createStamp} from "../services/apiCall";
import ReactGA from 'react-ga4';
const task="game3"


function ClawMachineGame() {
  //ReactGA.pageview(window.location.pathname)
  let navigate = useNavigate();
  // const isInit = false;
  // const [ isInit, setIsInit ] = useState( false );
  const [authPromise,setAuthPromise]=useState(isAuthenticated());
  const [auth,setAuth]=useState();
  const [showGift,setShowGift]=useState(false);
  const canvasSize = { width: 350, height: 375 };

  const [ targetPopupActive, setTargetPopupActive ] = useState( true );
  const [ resultPopupActive, setResultPopupActive ] = useState( false );

  const [ isGameActive, setGameActive ] = useState( false );
  const [ readyState, setReadyState ] = useState( 0 );
  const [ isGrabbing, setIsGrabbing ] = useState( false );
  const [ counter, setCounter ] = useState( 30 );
  // const [ target, setTarget ] = useState({});
  const target = useRef({});

  const [ engineObj, setEngineObj ] = useState();
  const [ renderObj, setRenderObj ] = useState();

  const selectedProduct = useRef( null );
  const [ resultProduct, setResultProduct ] = useState({});
  const [ resultState, setResultState ] = useState(0);
  const [pid,setPid]=useState(null);


  const Engine = Matter.Engine,
      Events = Matter.Events,
      Render = Matter.Render,
      Runner = Matter.Runner,
      Bodies = Matter.Bodies,
      Body = Matter.Body,
      Composite = Matter.Composite,
      Composites = Matter.Composites,
      Constraint = Matter.Constraint,
      Common = Matter.Common,
      Svg = Matter.Svg,
      Vertices = Matter.Vertices;

  const wallWidth = 100;
  const wallOffset = 20;

  const [ grabberParts, setGrabberParts ] = useState({});

  var itemsStack;
  const grabberProps = {
    offset: 30,
    posStep: {
      x: 5,
      y: 5,
    },
    angularStep: 0.02,
    maxOpenAngle: 1,
    minOpenAngle: 0.4,
  }

  // const grabberMovement = {
  //   isLeft: false,
  //   isRight: false,
  //   isUp: false,
  //   isDown: false,
  //   openState: true,
  // }

  const [ grabberMovement, setMovement ] = useState({
      isLeft: false,
      isRight: false,
      isUp: false,
      isDown: false,
      openState: true,
  });

  var isInit = false;
  useEffect(() => {
      if( isInit ) return;
      isInit = true;

      // setTarget( selectRandomTarget() );
      target.current = selectRandomTarget();
      renderCanvas();
  }, []);

  useEffect(()=>{
    if(targetPopupActive || resultPopupActive){
        document.querySelector("body").style.overflow="hidden";
    }
    else{
        document.querySelector("body").style.overflow="auto";
    }
  },[targetPopupActive,resultPopupActive]);


  // === functions ===

  const selectRandomTarget = function(){
    const index = Math.floor( Math.random() *  GameInfo.targetPeople.length );
    return GameInfo.targetPeople [ index ];
  }

  const renderCanvas = async function(){

    if( typeof fetch == "undefined"){
      console.log("not support");
      return false;
    }

        Common.setDecomp( PolyDecomp );

        // create an engine
        const engine = Engine.create();
        setEngineObj( engine );

        // create a renderer
        const render = Render.create({
                          canvas: document.getElementById("main-canvas"),
                          engine: engine,
                          options: {
                            width:  canvasSize.width,
                            height: canvasSize.height,
                            background: "transparent",

                            showAngleIndicator: false,
                            wireframes: false
                          }
                      });

        setRenderObj( render );

        itemsStack = Composites.stack( 55, -750 , 2, 5, 50, 50, function( x, y ){
          return Bodies.circle( x, y, 45, {
              slop: 0,
              density: 10,
              friction: .5,
              restitution: .2,
              render: {
                sprite: {
                  texture: null,
                  xScale: 0.55,
                  yScale: 0.55,
                }
              }
          });
        });


        // get shuffled List
        var shuffledList = JSON.parse( JSON.stringify( GameInfo.productsList ));
        shuffledList.sort(() => ( Math.random() - .5 ));

        for( var i = 0; i < itemsStack.bodies.length; i++ ){
          const body = itemsStack.bodies[i];
          body.render.sprite.texture = shuffledList[i].img;
          body.label = `Product-${ shuffledList[i].id }`;
        }




        // walls
        const boundary = [
          Bodies.rectangle( - wallWidth / 2 - wallOffset, canvasSize.height / 2, wallWidth, canvasSize.height * 2, { isStatic: true }),
          Bodies.rectangle(canvasSize.width + wallWidth / 2 + wallOffset, canvasSize.height / 2, wallWidth, canvasSize.height * 2, { isStatic: true }),
          Bodies.rectangle(canvasSize.width / 2, canvasSize.height + wallWidth / 2 + wallOffset, canvasSize.width, wallWidth, { isStatic: true }),
        ];


        // === render grabber ===
        // handler svg
        var select = function( root, selector ){
          return Array.prototype.slice.call( root.querySelectorAll(selector) );
        };

        var loadSvg = function( url ){
          return fetch( url )
            .then(function(response){
                return response.text();
             })
            .then(function(raw){
                return (new window.DOMParser()).parseFromString(raw, "image/svg+xml");
            });
        };

        const vertexSets = await Promise.all([
            loadSvg( grabberLeftSvg ),
            loadSvg( grabberRightSvg ),
        ]).then(function( roots ){
            return roots.map(function( root ){
                return select( root, "path" )
                  .map( function(path){
                      return Vertices.scale( Svg.pathToVertices(path, 200), 0.025, -0.025 );
                  })
              });
        });

        grabberParts.stick =  {
          body: Bodies.rectangle( canvasSize.width / 2, - canvasSize.height / 2 + 20, 20, canvasSize.height, {
            isStatic: true,
            render: {
              sprite: {
                texture: grabberStickImg,
                xScale: 0.25,
                yScale: 0.25,
              }
            }
          }),
          pos: {
            x: canvasSize.width / 2,
            y: - canvasSize.height / 2 + 20,
          }
        };

        grabberParts.left = {
          body: Bodies.fromVertices( canvasSize.width / 2 - grabberProps.offset , 95 , vertexSets[0], {
              isStatic: true,
              friction: 1,
              render: {
                  opacity: 0,
              },
           }),
          pos: {
            x: canvasSize.width / 2 - grabberProps.offset,
            y: 95,
          },
          angle: 0,
        };


        grabberParts.right = {
          body: Bodies.fromVertices( canvasSize.width / 2 + grabberProps.offset, 95 , vertexSets[1], {
              isStatic: true,
              friction: 1,
              render: {
                opacity: 0,
              },
           }),
          pos: {
            x: canvasSize.width / 2 + grabberProps.offset,
            y: 95,
          },
          angle: 0,
        };


        grabberParts.sensor = {
          body: Bodies.circle( canvasSize.width / 2, 60, 10, {
            isStatic: true,
            isSensor: true,
            render: {
              opacity: 0,
            },
          }),
          pos: {
            x: canvasSize.width / 2,
            y: 60,
          }
        };

       grabberParts.left.body.parts.push(
           Bodies.rectangle( canvasSize.width / 2 - 45, 100, 1, 1, {
             isStatic: true,
             render: {
               sprite: {
                 texture: grabberLeftImg,
                 xScale: 0.25,
                 yScale: 0.25,
                 xOffset: -0.35,
               }
             },
          }),
       );
       grabberParts.right.body.parts.push(
           Bodies.rectangle( canvasSize.width / 2 + 45, 100, 1, 1, {
             isStatic: true,
             render: {
               sprite: {
                 texture: grabberRightImg,
                 xScale: 0.25,
                 yScale: 0.25,
                 xOffset: 0.35,
               }
             },
          }),
       );

       setCollisionMask( false );


       Composite.add( engine.world, [ itemsStack ] );
       Composite.add( engine.world, [ ...boundary ] );
       Composite.add( engine.world, [ grabberParts.left.body, grabberParts.right.body, grabberParts.stick.body, grabberParts.sensor.body ] );

        // = = = gameLoop = = =

       Render.run( render );

       function run() {
           window.requestAnimationFrame(run);
           renderGrabber();
           // checkBallPos();
           Engine.update(engine, 1000 / 60);
       };

       run();
  }

  useEffect(() => {
        if( !engineObj ) return;
        Events.on( engineObj, "collisionStart", inSensorEvent );
        Events.on( engineObj, "collisionEnd", outSensorEvent );

        return function(){
            Events.off( engineObj, "collisionStart", inSensorEvent );
            Events.off( engineObj, "collisionEnd", outSensorEvent );
        }

  }, [ isGameActive, isGrabbing ]);


    const inSensorEvent = function(event){

       if( !isGameActive ) return false;

       var pairs = event.pairs;
       for( var i = 0, j = pairs.length; i != j; ++i ){
          var pair = pairs[i];

            if( isGrabbing && ( pair.bodyA == grabberParts.sensor.body || pair.bodyB == grabberParts.sensor.body ) ){

                // console.log("try??", pair.bodyA, pair.bodyB );
                  tryGrabbing();

                  if( pair.bodyA == grabberParts.sensor.body ){
                      // setSelectedProduct( pair.bodyB );
                      selectedProduct.current = pair.bodyB;
                  }else if ( pair.bodyB == grabberParts.sensor.body ){
                      // setSelectedProduct( pair.bodyA );
                      selectedProduct.current = pair.bodyA;
                  }
            }

       }
  };

  const outSensorEvent = function( event ){
       if( !isGameActive ) return false;

       var pairs = event.pairs;
       for( var i = 0, j = pairs.length; i != j; ++i ){
          var pair = pairs[i];

            if( pair.bodyA == grabberParts.sensor.body && pair.bodyB == selectedProduct.current ){
              console.log("remove:");
              selectedProduct.current = null;
              // setSelectedProduct( null );
            }else if( pair.bodyB == grabberParts.sensor.body && pair.bodyA == selectedProduct.current ){
              console.log("remove:");
              selectedProduct.current = null;
              // setSelectedProduct( null );
            }

       }
  }


  const checkBallPos = function(){
    // reset pos if ball is outside

    for( var i = 0; i < itemsStack.bodies.length; i++ ){
      if( itemsStack.bodies[i].position.y > canvasSize.height ){

          // is outside

          // Matter.Body.setPosition( itemsStack.bodies[i], { x: render.options.width / 2, y: -200 });
          // itemsStack.bodies[i].velocity = 0;

      }
    }
  }

  const countDownStr = useMemo(function(){
      // const m = Math.floor( counter / 60 );
      // const s = counter % 60;
      //
      // var str = "";
      // if( m > 0 ){
      //   str += ( m + "分" );
      // }
      //
      // if( s > 0 ){
      //   str += ( s + "秒");
      // }else if ( m > 0 ){
      //   str += "鐘"
      // }
      //
      // return str;
      return counter + "秒";
  }, [ counter ])


  const intro = function(){

      setTargetPopupActive( false );

      const introInterval = setInterval( function(){
        setReadyState( function( prevReady ){
            if( prevReady == 3 ){
              clearInterval( introInterval );
              gameStart();
            }
            return prevReady + 1;
        }, []);

      }, 1000 );
  }



  const gameStart = function(){
      setGameActive(() => true);

      setCollisionMask( true );
      countDown();
  }




  const countdownInterval = useRef();
  var isCounting = false;
  const countDown = function(){
      if( isCounting ){ return false; }
      isCounting = true;

      countdownInterval.current = setInterval(function(){

          setCounter( function( prevCount ){

            if( prevCount <= 0 ){
                clearInterval( countdownInterval.current );
                // endGame();
                return 0;
            }

            return prevCount - 1;
          }) ;

      }, 1000 );
  }


  const setCollisionMask = function( value ){
    [ grabberParts.stick.body, grabberParts.left.body, grabberParts.right.body ]
      .forEach( function(body){
        if( value ){
           body.collisionFilter.mask = 1;
        } else {
           body.collisionFilter.mask = 0;
        }
      });
  }


  const rotateByPoint = function( item, rotation, point ){
    var cos = Math.cos(rotation),
        sin = Math.sin(rotation);

    var dx = item.body.position.x - point.x,
        dy = item.body.position.y - point.y;

    item.pos = {
      x: point.x + (dx * cos - dy * sin),
      y: point.y + (dx * sin + dy * cos)
    }

    item.angle += rotation;

    Body.setPosition(item.body, item.pos);
    Body.rotate(item.body, rotation);

  }

  const grabberUpdatePos = function( x, y ){
      [ "stick", "left", "right", "sensor" ].forEach((part, i) => {
        grabberParts[ part ].pos.x += x;
        grabberParts[ part ].pos.y += y;
      });
  }


  const renderGrabber = function(){

    if( grabberMovement.isLeft && grabberParts.stick.pos.x > wallOffset ){
      grabberUpdatePos( - grabberProps.posStep.x, 0 );
    }
    if( grabberMovement.isRight && grabberParts.stick.pos.x < canvasSize.width - wallOffset ){
      grabberUpdatePos( grabberProps.posStep.x, 0 );
    }

    if( grabberMovement.isDown ){
        if( grabberParts.stick.pos.y < canvasSize.height / 2 - 100 ){
            grabberUpdatePos( 0, grabberProps.posStep.y );
        }else{
            grabberMovement.isDown = false;
            tryGrabbing();
        }
    }
    if( grabberMovement.isUp ){
        if( grabberParts.stick.pos.y > - canvasSize.height / 2 + 20 ){
            grabberUpdatePos( 0, - grabberProps.posStep.y );
        }else{
            grabberMovement.isUp = false;
            finishGrabbing();
        }
    }

    Body.setPosition( grabberParts.stick.body, grabberParts.stick.pos );
    Body.setPosition( grabberParts.left.body, grabberParts.left.pos );
    Body.setPosition( grabberParts.right.body, grabberParts.right.pos );
    Body.setPosition( grabberParts.sensor.body, grabberParts.sensor.pos );


    const rotatePoint = { x: grabberParts.stick.pos.x, y: grabberParts.stick.pos.y + canvasSize.height / 2 + 10};
    if( grabberMovement.openState ){
        if( grabberParts.left.angle - grabberParts.right.angle <= grabberProps.maxOpenAngle ){
            rotateByPoint( grabberParts.left, grabberProps.angularStep, rotatePoint );
            rotateByPoint( grabberParts.right, - grabberProps.angularStep, rotatePoint );
        }
    }else{
        if( grabberParts.left.angle - grabberParts.right.angle >= grabberProps.minOpenAngle ){
            rotateByPoint( grabberParts.left, - grabberProps.angularStep, rotatePoint );
            rotateByPoint( grabberParts.right, grabberProps.angularStep, rotatePoint );
        }
    }

  }



  var isEnd = false;
  const endGame = function( productId ){
      if( isEnd ) return false;
      isEnd = true;

      if( countdownInterval.current ){
        clearInterval( countdownInterval.current );
      }

      setGameActive( false );

      grabberMovement.isLeft = false;
      grabberMovement.isRight = false;
      grabberMovement.isUp = false;
      grabberMovement.isDown = false;

      // setResultProduct( GameInfo.productsList.find(p => p.id == target.productId ) );
      if( !productId ){
        setResultState( 0 );
      }else{
        setResultState( target.current.product.id == productId ? 2 : 1 );
      }

      setResultPopupActive( true );

  }

  useEffect(()=>{
    if( counter == 0 ){
      endGame();
    }
  }, [ counter ])



  // === === === evenet functions === === ===
  const leftPressed = function(){
    if( !isGameActive ) return false;
    grabberMovement.isLeft = true;

    // console.log("left+" , grabberMovement);
  }
  const rightPressed = function(){
    if( !isGameActive ) return false;
    grabberMovement.isRight = true;

    // console.log("right+", grabberMovement);
  }

  const leftReleased = function(){
    // if( !isGameActive ) return false;
    grabberMovement.isLeft = false;

    // console.log("left-", grabberMovement);
  }
  const rightReleased = function(){
    // if( !isGameActive ) return false;
    grabberMovement.isRight = false;

    // console.log("right-", grabberMovement);
  }

  const startOnClick = function(){
      if( !isGameActive ) return false;

      if( !grabberMovement.openState ){
        grabberMovement.openState = true;
        return;
      }


      if( isGrabbing ){
        tryGrabbing();
        return;
      };

      setIsGrabbing(()=>true);

      grabberMovement.openState = true;
      grabberMovement.isDown = true;
  }


  const tryGrabbing = function (){
      grabberMovement.isDown = false;

      grabberMovement.openState = false;
      setTimeout( function(){
        grabberMovement.isUp = true;
      }, 500);
  }


  const finishGrabbing = function(){
      setIsGrabbing(() => false);

      if( selectedProduct.current ){
          const _pid = selectedProduct.current.label.replace("Product-", "");
          setPid(_pid);
          endGame( _pid );
      }
  };


//  === game flow ===

  const tryAgain = function(){
    window.location.reload();
  }

  const next=()=>{
    createStamp(task)
    .then((res)=>res.json())
    .then((data)=>{
      console.log(data);
      if(data.result){
        ReactGA.event({
          category: task,
          action: task,
        })
        ReactGA.event({
          category: task,
          action: "createStamp",
          label: task,
          value:1
        })
        setShowGift(true);
      }
      else{
        navigate('/tutorial/4')
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      navigate('/tutorial/4');
    });
    
  }

  return (
    <div>
        <Header
          titleImg={headerImg}
          backBtnOnClick={() => navigate('/tutorial/3')}
        />

        <div className="canvas-con">

            <div className="top-row">
                <div>
                  <span>送禮目標</span>
                  <span className="avatar-con">
                      <img src={ target.current.avatarTitleImg } />
                  </span>
                  <span><img className={"title-img"} src={ target.current.titleImg } /></span>
                  {/* <span>{ target.current.name }</span> */}
                </div>
                <div>{ countDownStr }</div>
            </div>

            <div className="inner-con">
                <div className={ `canvas-cover ${ readyState <= 3 ? "active" : "" }`}>
                    <div className="cover-prompt">
                        <span className={ readyState == 0 ? "active" : "" }>Ready?</span>
                        <span className={ readyState == 1 ? "active" : "" }>3</span>
                        <span className={ readyState == 2 ? "active" : "" }>2</span>
                        <span className={ readyState == 3 ? "active" : "" }>1</span>
                    </div>
                </div>
                <canvas id="main-canvas" className="game-canvas" width="350" height="375"></canvas>
                <div className="canvas-bg"></div>
            </div>

            <div className="btn-row">
                <button className="pinkBtn"
                        onMouseDown={ leftPressed }  onMouseUp={ leftReleased } onMouseLeave={ leftReleased }
                        onTouchStart={ leftPressed } onTouchEnd={ leftReleased }
                        disabled={ !isGameActive }
                 >
                    <img src={ arrowLeft } />
                </button>
                <button className="pinkBtn"
                        onMouseDown={ rightPressed } onMouseUp={ rightReleased } onMouseLeave={ rightReleased }
                        onTouchStart={ rightPressed } onTouchEnd={ rightReleased }
                        disabled={ !isGameActive }
                >
                    <img src={ arrowRight }/>
                </button>

                <button className="orangeBtn"
                        onClick={ startOnClick }
                        disabled={ !isGameActive }
                >
                    <img src={ goLabel }/>
                </button>
            </div>
        </div>

        <div id="target-popup-con" className={ `claw-popup ${ targetPopupActive ? "active":"" }` }>
            <div className="claw-popup-blur-bg"></div>
            <div className="inner-con">
                <div className="upper">送禮目標</div>
                <div className="lower">
                    {/* <div>{ target.current.name }</div> */}
                    <img src={ target.current.avatarImg } />
                </div>
            </div>

            <button onClick={ intro }>繼續</button>
        </div>


        <div id="result-popup-con" className={ `claw-popup ${ resultPopupActive && target.current ? "active":"" }` }>
        <div className="claw-popup-blur-bg"></div>
            <div className="inner-con">
                <div className="upper">
                  <div className={`deco ${ resultState == 2 ?　"active":"" }`}>
                        <span className={ resultState == 0 ? "active": "" } >夾唔到添..</span>
                        <span className={ resultState == 1 ? "active": "" } >不如再試吓</span>
                        <span className={ resultState == 2 ? "active": "" } >你揀得啱呀</span>
                    </div>
                  </div>
                <div className="lower">
                    {(pid && GameInfo.productsList[pid].pImg) && <div style={{textAlign:"center"}} className={`prompt wrong-prompt ${ resultState == 1 ? "active":"" } `}>
                      揀呢個都唔錯～<br/>但係唔知會唔會仲有更啱心意嘅禮物呢？
                    </div>}

                    {!(pid && GameInfo.productsList[pid].pImg) && <div style={{textAlign:"center"}} className={`prompt wrong-prompt ${ resultState == 1 ? "active":"" } `}>
                      你揀嘅都唔錯～<br/>但依個好似更啱心意啵～
                    </div>}

                    <div className={`prompt missing-prompt ${ resultState == 0 ? "active":"" } `}>
                      差少少就夾到啦，俾多次機會你！
                    </div>

                    <div className={`inner ${ resultState > 0 ? "active": "" } ${resultState==2?"correct":""}`}>
                      <img src={ (pid && GameInfo.productsList[pid].pImg)?GameInfo.productsList[pid].pImg:(target?.current?.product?.img) } />

                      <span dangerouslySetInnerHTML={{__html:(pid && GameInfo.productsList[pid].pImg)?GameInfo.productsList[pid].name:(target?.current?.product?.name)}}/>
                    </div>
                </div>
            </div>

            <button className={ resultState == 0 ? "active":"" } onClick={ tryAgain }>再嚟多次</button>
            <button className={ resultState > 0 ? "active":"" } onClick={ resultState==1?tryAgain:next }>繼續</button>
        </div>
        {showGift && (<GiftModule nextBtnOnClick={()=>navigate('/tutorial/4')}/>)}
    </div>
  );
}

export default ClawMachineGame;
