import React from 'react';
import { Routes, Route, Outlet, useLocation } from "react-router-dom";

import Selfie from "./pages/Selfie";
import Tutorial from "./pages/Tutorial";
import Slider from "./pages/Slider";
import Landing from "./pages/Landing";
import Share from "./pages/Share";
import Sticker from "./pages/Sticker";
import StickerEditor from './pages/StickerEditor';
import Quiz from './pages/Quiz';
import QuizLanding from './pages/QuizLanding';
import QuizResult from './pages/QuizResult';
import Reward from './pages/Reward';
import FontText from './pages/FontTest';
import ClawMachineGame from './pages/ClawMachineGame';
import LandingDesktop from './pages/LandingDesktop';

import TestShare from './pages/shareTest';

import ReactGA from 'react-ga4';
const TRACKING_ID = "G-38RCRG7Y7T"
ReactGA.initialize(TRACKING_ID)


export default function App() {
  let location = useLocation();
  React.useEffect(() => {
    if (typeof window !== "undefined" && typeof window._jf !== 'undefined') {
      try {
        window._jf.flush();
        console.log('reloaded font');
      } catch (e) {

      }
    }
  }, [location]);
  React.useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      const script = document.createElement('script');
      console.log("load font");
      script.src = "//s3-ap-northeast-1.amazonaws.com/justfont-user-script/jf-64431.js";
      script.async = true;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      }
    }
  }, []);

  const [width, setWidth] = React.useState(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  React.useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = width <= 450;

  console.log(isMobile);

  if(!isMobile){
    return(
      <Routes>
         <Route path="/" element={<Layout />}>
          <Route index element={<LandingDesktop />} />
          <Route path="*" element={<LandingDesktop />} />
        </Route>
      </Routes>
    )
  }

  return (
    <div>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Landing />} />
          <Route path="reward" element={<Reward />} />
        </Route>
      </Routes>
    </div>
  );
}

function Layout() {
  return (
    <div>
      <Outlet />
    </div>
  );
}
