import coupon_divider_black from "../assets/images/coupon_divider_black.svg";
import icon_minus from "../assets/images/icon_minus.svg";
import icon_plus from "../assets/images/icon_plus.svg";
import coupon_icon_mustache from "../assets/images/coupon_icon_mustache.svg";

import styles from '../assets/styles/Coupon.module.scss';

export default function CouponLowerModule({addQ,minusQ,remainSticker,curQuantity,coupon,couponNotReady, showQR, confirmRedeem, onRedeemClick, confirmRedeemOnClick, cancelRedeemOnClick}) {
    return (  
                <div className={styles.coupon_popup_lower_wrap}>
                    <div className={styles.ccoupon_popup_lower_con}>
                        {/* <!-- Coupon divider --> */}
                        <img src={coupon_divider_black} alt="coupon_divider_black" className={styles.coupon_popup_divider}/> 
                        {/* <!-- When coupon is ready to redeem  */}
                        {(!couponNotReady&&!confirmRedeem)?<div className={styles.coupon_popup_inner_con}>
                            <div className={styles.coupon_popup_qty_con}>
                                <div onClick={minusQ} className={styles.coupon_popup_qty_icon_con}>
                                    <img style={{opacity:curQuantity>1?1:0.2}} src={icon_minus} alt="icon_minus" className={styles.coupon_popup_qty_icon}/> 
                                </div>
                                <div className={styles.coupon_popup_qty_text_con}>
                                    <div className={styles.coupon_popup_qty_text}>{curQuantity}</div>
                                </div>
                                <div onClick={addQ} className={styles.coupon_popup_qty_icon_con}>
                                    <img style={{opacity:(curQuantity+1)*coupon.printingRequest<=remainSticker?1:0.2}} src={icon_plus} alt="icon_plus" className={styles.coupon_popup_qty_icon}/> 
                                </div>
                            </div>
                            <div className={styles.popup_btn_con}>
                                <button onClick={onRedeemClick} className={styles.coupon_inner_btn}>
                                    <div className={styles.coupon_inner_btn_wrap}>
                                        <div>確認兌換</div>
                                        <img src={coupon_icon_mustache} alt="coupon_icon_mustache" className={styles.coupon_claim_redeem_icon}/>
                                        <div>{coupon.printingRequest}</div>
                                    </div>     
                                </button>
                            </div>
                        </div>:null}
                        {/* <!-- When confirm redeem coupon --> */}
                       {(confirmRedeem)?<div className={styles.coupon_popup_inner_con}>
                            <div className={styles.coupon_popup_confirm_left_con}>
                                <div className={styles.coupon_popup_confirm_title}>確認使用?</div>
                                <div className={styles.coupon_popup_confirm_text}>現金券只能顯示一次</div>
                            </div>
                            <div className={styles.coupon_popup_confirm_right_con}>
                                <button className={styles.coupon_popup_confirm_btn_outline} onClick={cancelRedeemOnClick}>取消</button>
                                <button className={styles.coupon_popup_confirm_btn_fill} onClick={confirmRedeemOnClick}>確認</button>
                            </div>
                        </div>:null}
                        
                        {/* <!-- When QR code is shown on screen  */}
                        
                        {(showQR)?<div className={styles.coupon_popup_inner_con}>
                            <button onClick={cancelRedeemOnClick} className={styles.coupon_popup_done_btn}>完成</button>
                        </div>:null}
                    </div>
                </div>
    );
  }



