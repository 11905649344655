
import reward_coupon_bg_right from "../assets/images/reward_coupon_bg_right.svg";
import reward_coupon_right_title_img from "../assets/images/reward_coupon_right_title.png";
import PizzaHutCouponModule from "./PizzaHutCouponModule";
import PHDCouponModule from "./PHDCouponModule";
import styles from '../assets/styles/Reward.module.scss';
import GeneralCouponModule from "./GeneralCouponModule";

export default function RewardRightModule({showRedeemded,onClick,list,_useCoupon}) {
    var redeemStatus = showRedeemded;

    function onChangeStatus(){
        redeemStatus=false;
        console.log(redeemStatus);
    }
  return (  
    <div className={`${styles.reward_coupreward_coupon_wrapon_con} ${redeemStatus?styles.coupon_claimed:styles.hide_under}`}>
        <div className={styles.reward_coupon_con}>
            <img src={reward_coupon_bg_right} className={styles.reward_coupon_bg}/>  
            <div className={styles.reward_coupon_title_con}>
                <div className={styles.reward_coupon_title_left}  onClick={()=>onClick(true)}></div>
                {/* <div className={styles.reward_coupon_title_right} onClick={()=>onClick(false)}>已兌換 <u>{list.length}</u></div> */}
                <div className={styles.reward_coupon_title_right} onClick={()=>onClick(false)}><img src={reward_coupon_right_title_img} className={styles.titleRightImg}/> 
                    <span className={styles.couponNum}>
                        {list.length.toString().split("").map((e,i)=>(<img src={require(`../assets/images/reward/coupon_num/`+e+".svg")}/>))}
                    </span>
                </div>
            </div>
            <div className={styles.reward_coupon_inner_bg}>
                <div className={styles.reward_coupon_inner_con}>
                    {list.map((e,i)=>(
                    <GeneralCouponModule key={i} onClick={()=>_useCoupon(i)} couponImg={e.couponID.couponImg} couponClaimedImg={e.couponID.couponClaimedImg} redeemCase={e.state=="Unused"?'canUse':'used'} />
                    ))}
                </div>
            </div>
        </div> 
    </div>
  );
}