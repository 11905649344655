import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import bgSlider from '../assets/images/bg_slider.png';
import Header from '../components/HeaderModule';
import LoginModule from "../components/Login";
import styles from '../assets/styles/Slider.module.scss';
import SliderModule from '../components/SliderModule';
import slide1 from '../assets/images/game_slide1.png';
import slide2 from '../assets/images/game_slide2.png';
import slide3 from '../assets/images/game_slide3.png';
import slide4 from '../assets/images/game_slide4.png';

import { useNavigate, useSearchParams } from "react-router-dom";
import isAuthenticated from "../middlewares/isAuthenticated";
import Cookies from 'js-cookie';
import ReactGA from 'react-ga4';
import {login,getStamp} from "../services/apiCall";


const buttonStatusList = ["SliderChallenge", "SliderReChallenge", "SliderChallengeLockedButton"];
//const buttonStatus = buttonStatusList[1];


function Slider() {
  let navigate = useNavigate();

  const [showLogin, setShowLogin] = useState(false);
  const [firstShow, setFirstShow] = useState(true);
  const [authPromise,setAuthPromise]=useState(isAuthenticated());
  const [auth,setAuth]=useState();
  const [searchParams, setSearchParams] = useSearchParams();
  if(searchParams.get("newUser")){
    ReactGA.event({
      category: "newUserLogin",
      action: "newUserLogin",
      label: searchParams.get("newUser"),
    })
  }
  if(searchParams.get("invited")){
    ReactGA.event({
      category: "invited",
      action: "createStamp",
      label: "invited",
      value:1
    })
  }
  const [curSlide,setCurSlide]=useState(0);

  const [sliderImageList,setSliderImageList] = useState([
    {
      name:"game1",
      img:slide1,
      btn:0,
      onClick:()=>{navigate('/tutorial/1')}
    }, 
    {
      name:"game2",
      img:slide2,
      btn:2,
      onClick:()=>{navigate('/tutorial/2')}
    }, 
    {
      name:"game3",
      img:slide3,
      btn:2,
      onClick:()=>{navigate('/tutorial/3')}
    },
    {
      name:"game4",
      img:slide4,
      btn:2,
      onClick:()=>{navigate('/tutorial/4')}
    }
  ]);

  function onSlideChange(i){
    setCurSlide(i);
    //console.log(i);
  }

  function btnOnClick(i){
    if(sliderImageList[i].btn!=2){
      if(auth){
        return sliderImageList[curSlide].onClick;
      }
      else{
        return ()=>setShowLogin(true)
      }
    }
    return null;
  }

  useEffect(() => {
    if(firstShow){
      authPromise.then(ret=>{
        console.log(ret);
        setAuth(ret);
      })
      
      setFirstShow(false);
    }
    
  });

  useEffect(()=>{
    console.log(auth);
    if(!auth){
      //setShowLogin(true);
    }
    else{
      var res=getStamp()
      .then((res)=>res.json())
      .then((data)=>{
        //console.log(data);
        var temp=sliderImageList.concat();
        console.log(temp);
        var progress=0;
        data.Printing.forEach((e,i)=>{
          if(progress<temp.length && e.type==temp[progress].name){
            temp[progress].btn=1;
            progress++;
            if(progress>=temp.length)
              return;
          }
        });
        if(progress<temp.length){
          temp[progress].btn=0;
        }
        console.log(temp);
        setSliderImageList(temp);
      });
      //console.log(res);
    }
  },[auth])


  return (
    <div>
      <div>
        <div className={styles.slider_center}>
            <Header
              backBtnOnClick={() => navigate('/')}
            />
            <div className={styles.bgDiv}>
              <img src={bgSlider} className={styles.slider_bg_1} alt="bgSlider"/>
            </div>
            <SliderModule 
              btnOnClick={btnOnClick(curSlide)} 
              onSlideChange={onSlideChange} 
              buttonStatus={buttonStatusList[sliderImageList[curSlide].btn]} 
              sliderImageList={sliderImageList}
            />
         </div>
      </div>
      
      {showLogin && (<LoginModule isPopup={true} loginBtnOnClick={()=>{login("slider");}} dismissBtnOnClick={()=>{setShowLogin(false)}}/>)}
    </div>
  );
}

export default Slider;
