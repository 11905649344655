import tutorDummy from '../assets/images/tutor_dummy.png';
import Header from '../components/HeaderModule';
import TutorialContentModule from '../components/TutorialContentModule';
import '../assets/styles/Tutorial.css';
import { useLocation, useParams, useNavigate } from "react-router-dom";
import React, { useRef, useLayoutEffect, useState, useEffect } from "react";

const buttonImage = [tutorDummy, tutorDummy];


function Tutorial() {
  let navigate = useNavigate();
  let {id}=useParams();
  const [firstShow, setFirstShow] = useState(true);

  const tutorials=[
    {
      name:"game1",
      onClick:()=>navigate("/selfie"),
      desc:"HOHOHO！笑吓啦喂～過聖誕梗係要好似SANTA咁大笑幾聲，先至有聖誕氣氛！笑一笑，再拍個照～立即selfie，你個樣就會出現SANTA版鬍鬚，人人齊變SANTA啦！"
    },
    {
      name:"game2",
      onClick:() => navigate("/sticker"),
      desc:"想show吓你獨一無二嘅個人聖誕風格？不如upload你嘅自選相片，自製你嘅電子聖誕咭，或者揀我哋為你準備嘅sticker，人人立即share心意畀朋友！"
    },
    {
      name:"game3",
      // imgDivstyle:{marginTop: "-80%"},
      onClick:() => navigate("/claw-machine-game"),
      desc:"送聖誕禮物，講求識揀！立即玩小遊戲，為唔同嘅親友搵返最啱佢心意嘅禮物啦！"
    },
    {
      name:"game4",
      onClick:() => navigate("/share"),
      desc:"Share條link俾3個好朋友，可以立即獲得萬寧聖誕鬍鬚襟章 乙個！你嘅朋友成功登入，雙方仲可以額外獲贈鬍鬚襟章 乙個！仲唔快啲人人齊share？"
    }
  ]

  useEffect(() => {
    if(firstShow){
      document.querySelector("body").classList.remove("deep_color_bg");
      document.querySelector("body").classList.remove("green_color_bg");
    }
  });

  return (
    <div className="App">
      <div className="App-body">
      <div className="tutorial_center">
        <Header 
          hasTitle={false}
          backBtnOnClick={() => navigate('/slider')}
        />
        <TutorialContentModule 
          index={id} 
          desc={tutorials[id-1].desc} 
          imgDivstyle={tutorials[id-1].imgDivstyle?tutorials[id-1].imgDivstyle:{}}
          buttonImage={require(`../assets/images/tutor_${id}.png`)} 
          onClick={tutorials[id-1].onClick}
        />
    </div>
      </div>
    </div>
  );
}

export default Tutorial;
