import bgLanding from '../assets/images/landing_end.png';
import rewardBtn from '../assets/images/reward_btn.png';
import manningLogo from '../assets/images/logo.png';
import videoCon from '../assets/images/video_con.png';
import santaTitle from '../assets/images/santa_title.png';
import giftLeft from '../assets/images/gift_left.png';
import giftRight from '../assets/images/gift_right.png';
import santaKid from '../assets/images/tag.png';
import descText from '../assets/images/landing_desc_text.svg';
import playBtn from '../assets/images/video_play_btn.png';

import { useNavigate } from "react-router-dom";
import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import PolicyModule from '../components/Policy';
import LoginModule from "../components/Login";

import isAuthenticated from "../middlewares/isAuthenticated";
import '../assets/styles/Landing.css';
import { login } from '../services/apiCall';
import ReactGA from 'react-ga4';
const HOST=process.env.REACT_APP_API_URL;


function Landing() {
    let navigate = useNavigate();
    const [showPolicy, setShowPolicy] = useState(false);
    const [showInviteLogin, setShowInviteLogin] = useState(false);
    const [showInviteErrorLogin, setShowInviteErrorLogin] = useState(false);
    const [authPromise,setAuthPromise]=useState(isAuthenticated());
    const [auth,setAuth]=useState();
    const [firstShow, setFirstShow] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();

    //console.log(searchParams.get("inviteCode"));
    //console.log(auth);

    if(searchParams.get("newUser")){
        ReactGA.event({
          category: "newUserLogin",
          action: "newUserLogin",
          label: searchParams.get("newUser"),
        })
      }
      if(searchParams.get("invited")){
        ReactGA.event({
          category: "invited",
          action: "createStamp",
          label: "invited",
          value:1
        })
      }
    

    useEffect(() => {
        if(firstShow){
            window.scrollTo(0,0);
            document.querySelector("body").classList.add("deep_color_bg");
            document.querySelector("body").style.overflow="hidden";
            authPromise.then(ret=>{
                setAuth(ret);
            })
        }
        setFirstShow(false);
    });

    useEffect(()=>{
        if(!auth && searchParams.get("inviteCode")){
            setShowInviteLogin(true);
        }
        if(!auth && searchParams.get("inviteError")){
            setShowInviteErrorLogin(true);
        }
    },[auth])
  

    useEffect(()=>{
        if(showPolicy||showInviteLogin){
            document.querySelector("body").style.overflow="hidden";
        }
        else{
            document.querySelector("body").style.overflow="auto";
        }
    },[showPolicy,showInviteLogin]);
    return (
        <div className="App" onTouchStart={(e)=>e.preventDefault()}>
            <div className="App-body">
                <div className="main_center">
                    {/* <!-- Bg Con --> */}
                    <div className="video_bg_con">
                        <img src={bgLanding} className="bgLandingImgWidth" alt="bgLandingImgWidth" />
                    </div>
                    {/* <!-- Manning Logo Con --> */}
                    <div className="manning_logo_con">
                        <img className="manning_logo_img" src={manningLogo} alt="manning_logo_img" />
                    </div>
                    {/* <!-- Video Con --> */}
                    <div className="video_con">
                        <div className="video_deco_con">
                            <div className="video_img_con">
                                <img src={videoCon} alt="videoCon" />
                            </div>
                            <div className="video_title_con">
                                <img src={santaTitle} alt="santaTitle" />
                            </div>
                            <img src={giftLeft} className="deco_gift_left" alt="giftLeft" />
                            <img src={giftRight} className="deco_gift_right" alt="giftRight" />
                            <div className="video_char_con">
                                <img src={santaKid} alt="santaKid" />
                            </div>
                            <div className="video_play_btn_con">
                                <a href="https://www.youtube.com/watch?v=jYapc--WkA8" target="_blank" className="video_play_btn">
                                    <img src={playBtn} className="playBtnWidth" alt="playBtnWidth" />
                                </a>
                            </div>
                            <img src={descText} className='desc_con'/>
                            <img src={rewardBtn} className="reward_btn" onClick={() => { navigate('/reward') }}/>
                        </div>
                    </div>
                    
                </div>
            </div>
            {showPolicy && (<PolicyModule onClick={()=>setShowPolicy(false)}/>)}
            {showInviteLogin && (<LoginModule isPopup={true} isInvited={true} loginBtnOnClick={()=>{window.location.href=HOST+"/api/invite?inviteCode="+searchParams.get("inviteCode");}} dismissBtnOnClick={()=>{setShowInviteLogin(false)}}/>)}
            {showInviteErrorLogin && (<LoginModule isPopup={true} isInvitedError={true} loginBtnOnClick={()=>{login();}} dismissBtnOnClick={()=>{setShowInviteErrorLogin(false)}}/>)}
        </div>
    );
}

export default Landing;