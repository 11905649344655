import Cookies from 'js-cookie';
import { checkAuth } from '../services/apiCall';

export default async function isAuthenticated() {
    const token = Cookies.get('session-id', { domain: '.manningscampaign.com.hk' }) || Cookies.get('session-id');
    if (token === undefined || token === null || token === '') {
        return false;
    }
    else{
        let res=await checkAuth();
        if(res.status!=200){
            Cookies.remove('session-id', { path: '/',domain: '.manningscampaign.com.hk' });
            Cookies.remove('session-id',{path: '/'});
            return false;
            //console.log(res.status);
        }
        
    }

    return (token)
}