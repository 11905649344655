import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import StcikerEditor from '../components/StickerEditor';
import Header from '../components/RelativeHeader';
import "../assets/styles/Sticker.page.css";

import headerImg from '../assets/images/sticker/title.png';

import { useNavigate } from "react-router-dom";

import isAuthenticated from "../middlewares/isAuthenticated";

import {createStamp} from "../services/apiCall";
import GiftModule from '../components/Gift';
import ImagePopupModule from '../components/ImagePopupModule';
import ReactGA from 'react-ga4';
const task="game2"

function StickerEditor() {
  //ReactGA.pageview(window.location.pathname)
  let navigate = useNavigate();
  const [authPromise,setAuthPromise]=useState(isAuthenticated());
  const [auth,setAuth]=useState();
  const [showGift,setShowGift]=useState(false);
  const [showImagePopup,setShowImagePopup]=useState(false);
  const [imageURL,setImageURL]=useState(null);
  

  useEffect(()=>{
    if(showGift||showImagePopup){
        document.querySelector("body").style.overflow="hidden";
    }
    else{
        document.querySelector("body").style.overflow="auto";
    }
  },[showGift,showImagePopup]);

  function gameClear(){
    setShowImagePopup(false);
    createStamp(task)
    .then((res)=>res.json())
    .then((data)=>{
      console.log(data);
      if(data.result){
        ReactGA.event({
          category: task,
          action: task,
        })
        ReactGA.event({
          category: task,
          action: "createStamp",
          label: task,
          value:1
        })
        setShowGift(true);
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    }); 
  }

  function _setImageURL(url){
    setImageURL(url);
    setShowImagePopup(true);
  }

  return (
    <div className={"page"}>
      <Header
        titleImg={headerImg}
      />
      <StcikerEditor setImageURL={_setImageURL} gameClear={gameClear}/>
      {showGift && (<GiftModule nextBtnOnClick={()=>navigate('/tutorial/3')}/>)}
      {showImagePopup && <ImagePopupModule image={imageURL} onClick={gameClear}/>}
    </div>
  );
}

export default StickerEditor;
