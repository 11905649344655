import bgbtn from '../assets/images/back_btn.svg';
import styles from "../assets/styles/Header1.module.scss";


export default function Header({style,middleHtml,rightHtml,backBtnOnClick,hasTitle, titleImage}) {
  return (  
    <div style={style} className={styles.header_con}>
        <div className={styles.back_btn_con}>
            <button onClick={backBtnOnClick} className={hasTitle?styles.has_title_back_btn:styles.back_btn}>
              <img src={bgbtn} alt="bgbtn" />
            </button>
        </div>
        <div dangerouslySetInnerHTML={{__html:middleHtml}}></div>
          <div className={styles.header_long_width}>
            {hasTitle?<img src={titleImage} className={styles.header_title_img} alt='header_title'/>:<div/>}
          </div>
        <div className={styles.header_short_width} dangerouslySetInnerHTML={{__html:rightHtml}}></div>
    </div>
  );
}