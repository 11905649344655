
import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import coupon_pizza_hut from "../assets/images/coupon_pizza_hut.png";
import coupon_icon_mustache from "../assets/images/coupon_icon_mustache.svg";
import coupon_phd from "../assets/images/coupon_phd.png";
import reward_coupon_bg_left from "../assets/images/reward_coupon_bg_left.svg";
import reward_coupon_bg_right from "../assets/images/reward_coupon_bg_right.svg";
import coupon_phd_claimed from "../assets/images/coupon_phd_claimed.png";
import RewardsLeftModule from "./RewardsLeftModule";
import RewardRightModule from "./RewardsRightModule";
import RewardNoRedeemModule from "./RewardsNoRedeemModule";

import styles from '../assets/styles/Reward.module.scss';

export default function RewardCollectionModule({redeemCoupon,_useCoupon,noRedeem,couponList,userCouponList,showRight,setShowRight}) {
    
    var showRedeemded = true;
  return (  
    <div className={styles.reward_coupon_wrap}>
        {!noRedeem &&(<RewardsLeftModule redeemCoupon={redeemCoupon} list={couponList} onClick={(s)=>{setShowRight(s)}} redeemCase={"canRedeem"} showRedeemded={!showRight} />)}
        {!noRedeem &&(<RewardRightModule _useCoupon={_useCoupon} list={userCouponList} onClick={(s)=>{setShowRight(s)}} redeemCase={"canRedeem"} showRedeemded={showRight} />)}
        {noRedeem && (<RewardNoRedeemModule list={couponList}/>)}

                {/* <!-- UI when no coupon has been redeemed yet */}
                {/* <div className={`${styles.reward_coupon_con} ${styles.coupon_reward}`}>
                
                    <img src={reward_coupon_bg_full} className={styles.reward_coupon_bg}/>  
                    <div className={styles.reward_coupon_title_con}>
                        <div className={styles.reward_coupon_title_full}>獎賞</div>
                    </div>
                    <div className={styles.reward_coupon_inner_bg}>
                        <div className={styles.reward_coupon_inner_con}>
                            <div className={styles.coupon_inner_con}>
                                <img src={coupon_pizza_hut} className={styles.coupon_img}/>  
                                <button className={styles.coupon_inner_btn}>
                                    <div className={styles.coupon_inner_btn_wrap}>
                                        <div>兌換</div>
                                        <img src={coupon_icon_mustache} className={styles.coupon_claim_icon}/>
                                        <div>3</div>
                                    </div>       
                                </button>
                            </div>
                            <div className={styles.coupon_inner_con}>
                                <img src={coupon_phd} className={styles.coupon_img}/>  
                                <button className={styles.coupon_inner_btn}>
                                    <div className={styles.coupon_inner_btn_wrap}>
                                        <div>兌換</div>
                                        <img src={coupon_icon_mustache} className={styles.coupon_claim_icon}/>
                                        <div>4</div>
                                    </div>     
                                </button>
                            </div>
                        </div>
                    </div>
                </div>  */}
    </div>
  );
}