import coupon_pizza_hut from "../assets/images/coupon_pizza_hut.png";
import coupon_icon_mustache from "../assets/images/coupon_icon_mustache.svg";
import coupon_phd from "../assets/images/coupon_phd.png";
import reward_coupon_bg_full from "../assets/images/reward_coupon_bg_full.svg";
import PizzaHutCouponModule from "./PizzaHutCouponModule";
import PHDCouponModule from "./PHDCouponModule";
import reward_coupon_left_title_img from "../assets/images/reward_coupon_left_title.png";


import styles from '../assets/styles/Reward.module.scss';

export default function RewardNoRedeemModule({showRedeemded}) {
    const pizzaHutRedeemNum = 3;
    const phdRedeemNum = 4;
  return (  
    <div className={styles.reward_coupon_wrap}>
        {/* <!-- UI when no coupon has been redeemed yet */}
        <div className={`${styles.reward_coupon_con} ${styles.coupon_reward}`}>
                    <img src={reward_coupon_bg_full} className={styles.reward_coupon_bg}/>  
                    <div className={styles.reward_coupon_title_con}>
                        <div className={styles.reward_coupon_title_full}><img src={reward_coupon_left_title_img} className={styles.titleLeftImg}/></div>
                    </div>
                    <div className={styles.reward_coupon_inner_bg}>
                        <div className={styles.reward_coupon_inner_con}>
                        <PizzaHutCouponModule redeemCase={"canRedeem"} redeemNum={pizzaHutRedeemNum}/>
                            <PHDCouponModule redeemCase={"canRedeem"} redeemNum={phdRedeemNum}/>
                        </div>
                    </div>
        </div> 
    </div>
  );
}