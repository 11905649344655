import styles from '../assets/styles/Policy.module.scss';

function PolicyModule({onClick}) {
  return(
    <div>
        <div className={styles.coupon_center}>
            <div className={styles.popup_blur_bg}/>
            <div onClick={onClick} className={`${styles.popup_bg} ${styles.popup_scroll}`}>
                <div className={styles.popup_policy_con}>
                    <div className={styles.popup_policy_title}>【萬寧 - 人人Santa大募集】<br/>條款及細則 </div>
                    <div className={styles.popup_policy_inner_con}>
                        <div className={styles.popup_policy_inner_text}>
                            1. 本活動由DFI Development (HK) Limited以萬寧之名義舉辦。 <br/>
                            2. 是次活動供香港居民參與，每名參加者的參加次數不限，但只能獲奬一次。完成活動後，將於 2023年1 月 6 日於星島日報及The Standard公佈。<br/>
                            3. 此推廣活動的參加者記錄將以萬寧的電腦紀錄為準參加者不得異議。 <br/>
                            4. 此推廣活動的評審準則由萬寧決定，參加者不得異議。 <br/>
                            5. 得獎者不可將獎品兌換現金或其他貨品、售賣或轉讓。 <br/>
                            6 如有任何爭議，萬寧保留最終決定權而毋須另行通知。<br/>
                        </div>
                    </div>  
                    <div className={styles.popup_policy_inner_con}>
                        <div className={styles.popup_policy_inner_title}>保障個人資料</div>
                        <div className={styles.popup_policy_inner_text}>
                            7. 為了活動用途，Facebook需向參加者收集個人資料。如參加者無法提供要求之個人資料，萬寧將無法處理或接受有關登記。就此，各參加者須同意Facebook及萬寧按照保證私隱及資料保安政策及香港《個人資料（私隱）條例》使用參加者之個人資料 （包括任何轉送）作為是次活動用途。請檢閱我們的政策： <br/>
                            <a style={{color:'white'}} href="https://www.mannings.com.hk/privacy-policy" target="_blank">https://www.mannings.com.hk/privacy-policy</a><br/>
                            8. 參加者所提供之個人資料只限於登入此活動網頁之用。<br/>
                            9. 得獎者均同意萬寧保留於其廣告及宣傳品發表參加者姓名之權利。<br/>
                            10. 如有任何爭議，萬寧保留最終決定權。<br/>
                            11. 推廣生意的競賽牌照號碼：056428-33
                        </div>
                    </div>  
                </div>
            </div>
        </div>
    </div>
  );
}

export default PolicyModule;
