import CouponUpperModule from "./CouponUpperModule";
import CouponLowerModule from "./CouponLowerModule";
import qr_code_dummy from "../assets/images/qr_code_dummy.png";

import styles from '../assets/styles/Coupon.module.scss';

function CouponModule({addQ,minusQ,couponNotReady,showQrCode,confirmRedeemOnClick,cancelRedeemOnClick,remainSticker,curQuantity,coupon,dismiss,isRedeem,onRedeemClick}) {
    const showQR = false;
    const qr_code_image = qr_code_dummy;
    const redeemNum = 4;
    // function onRedeemClick(){
    //     confirmRedeem = true;
    // }
    // function cancelRedeemOnClick(){
    //     confirmRedeem = false;
    // }
    // function confirmRedeemOnClick(){
    //     showQR = true;
    //     confirmRedeem = false;
    // }
    console.log(coupon);
    const reqestSticker=curQuantity*coupon.printingRequest;
  return (
    <div>
      <div>
      <div className={styles.coupon_center}>
        <div className={styles.popup_blur_bg}/>
        <div className={styles.popup_bg}>
            {/* <!-- Coupon Con (change the img for the bg and logo to view the phd coupon) --> */}
            <div className={styles.coupon_popup_wrap}>
                {/* <!-- Coupon upper part --> */}
                <CouponUpperModule 
                  coupon={coupon} 
                  dismiss={dismiss} 
                  showQR={showQrCode} 
                  qr_code_image={qr_code_image}
                />
                {/* <!-- Coupon lower part --> */}
                <CouponLowerModule 
                  remainSticker={remainSticker}
                  coupon={coupon}
                  couponNotReady={couponNotReady}
                  curQuantity={curQuantity}
                  showQR={showQrCode} 
                  confirmRedeem={!isRedeem}
                  onRedeemClick={onRedeemClick}
                  cancelRedeemOnClick={cancelRedeemOnClick}
                  confirmRedeemOnClick={confirmRedeemOnClick}
                  addQ={addQ}
                  minusQ={minusQ}
                />
            </div>
            {/* <!-- Show no. of stamps needed to obtain reward --> */}
                {(!showQrCode && isRedeem && (couponNotReady||reqestSticker>remainSticker))?<div className={styles.number_of_stamp_style}>拎多  {reqestSticker- remainSticker}  個鬍鬚襟章先可以換到</div>:null}
        </div>
        
       
    </div>
      </div>
    </div>
  );
}

export default CouponModule;


